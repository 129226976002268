<script setup lang="ts">
type Props = {
  src?: string;
  provider?: string;
  fallback?: string;
  // 読み込み中アニメーション
  loadingAnimation?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  fallback: "/images/fallback_image.png",
  loadingAnimation: false,
});

const imgRef = useTemplateRef("imgRef");

// 一度目のエラーでproviderを外す
// それでもエラーならフォールバック画像に
const errorCount = ref(0);

const isError = computed(() => {
  return errorCount.value >= 2;
});

const attrs = useAttrs();

const modifiers = computed(() => {
  // provider指定がない、エラー1段階目でprovider指定を外した場合
  if (!props.provider || errorCount.value > 0) return {};

  return {
    gravity: attrs.gravity,
    resize: attrs.resize,
    crop: attrs.crop,
    enlarge: attrs.enlarge ?? false,
    extend: attrs.extend ?? false,
  };
});
// 読み込み完了フラグ
// 読み込み中アニメーションをするか?
const isLoaded = ref(props.loadingAnimation && !attrs.preload ? false : true);
if (props.loadingAnimation) {
  // 読み込み完了後のアニメーション
  // 透明度を0から1に変化させフェードインする

  // 2秒後に読み込み完了フラグを立てる
  setTimeout(() => {
    isLoaded.value = true;
  }, 2000);

  watch(
    () => isLoaded.value,
    (bool) => {
      if (!bool) return;

      const img = imgRef.value;

      useAnimate(img?.$el, [{ opacity: 0 }, { opacity: 1 }], {
        duration: 500,
        direction: "alternate",
        easing: "cubic-bezier(0.46, 0.03, 0.52, 0.96)",
      });
    },
    {
      immediate: true,
    },
  );
}
</script>

<template>
  <NuxtImg
    ref="imgRef"
    class="text-gray-400 text-xs"
    :class="{
      'animate-pulse': !isLoaded,
    }"
    :provider="!errorCount ? provider : ''"
    :src="isError ? fallback : src"
    :modifiers="modifiers"
    @error="errorCount += 1"
    @load="isLoaded = true"
  />
</template>
